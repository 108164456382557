import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";
import ListGroup from "react-bootstrap/ListGroup";

import { EventForm } from "./EventForm";
import { EventItem } from "./EventItem";
import { EventFormModal } from './EventFormModal'
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import dayjs from 'dayjs';

export const UpcomingEvents = (props) => {

    const [events, setEvents] = useState(props.events);
    const minLength = props.skipFirst ? 1 : 0;
    
    const eventList = events.map((event, index) => (
        index == 0 && props.skipFirst ? '' : <EventItem
            id={event.id}
            name={event.name}
            eventDate={event.eventDate}
            eventTime={event.eventTime}
            location={event.location}
            description={event.description}
            key={event.id}
        />
    ));

    return (
        <div className="data-area-div">
            <h1>{props.heading}</h1>
            <ListGroup className="todo-list">
                {events.length > minLength ? eventList : 'No events scheduled'}
            </ListGroup>
        </div>
    );
}
