import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Modal, TimePicker, Select, Radio } from 'antd';
import dayjs from 'dayjs';
import { TextEditor } from "./TextEditor";
const { TextArea } = Input;

export const GameFormModal = ({ open, title, values, okText, onCreate, onCancel }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
    if (values && !confirmLoading) {
        console.log({values},{confirmLoading});
        form.setFieldsValue({
            id: values?.id,
            name: values?.name,
            startDate: dayjs(values?.startDate),
            startTime: dayjs(values?.startTime),
            location: values?.location,
            home: values?.home,
            homeScore: values?.homeScore,
            visitor: values?.visitor,
            visitorScore: values?.visitorScore,
            snacks: values?.snacks,
            description: values?.description,
            gameNotes: values?.gameNotes
        });
    }
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        onCreate(values).then(() => {
            console.log('ok', {values});
          setConfirmLoading(false);
          form.resetFields();
        });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    console.log('reset');
    onCancel();
  };

  const teams = [
    {
      value: 'Bulls'
    },
    {
      value: 'Cannon Ballers'
    },
    {
      value: 'Grizzlies'
    },
    {
      value: 'Iron Birds'
    },
    {
        value: 'Iron Pigs'
    },
    {
        value: 'Lugnuts'
    },
    {
      value: 'Mud Hens'
    },
    {
        value: 'Red Wings'
    },
    {
        value: 'River Bandits'
    },
    {
        value: 'River Cats'
    },
    {
        value: 'Tin Caps'
    },
    {
      value: 'Trash Pandas'
  },
];

  return (
    <Modal
      open={open}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      style={{
        top: 24,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item 
          name="startDate"
          label="Game Date"
          rules={[{ 
            required: true, 
            message: "Please enter a date for the game" 
          }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 12px)'
        }}
    >
            <DatePicker
                style={{ width: '100%' }}
            />
        </Form.Item>
        <Form.Item 
          name="startTime"
          label="Game Time"
          rules={[{ 
            required: true, 
            message: "Please enter a time for the game" 
          }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 12px)',
            marginLeft: '24px'
        }}
    >
          <TimePicker 
            format='hh:mm A' 
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item 
          name="location"
          label="Location"
          rules={[{ 
            required: true, 
            message: "Please enter a location for the game" 
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="locationLink"
          label="Location Link"
        >
          <Input />
        </Form.Item>
        <Form.Item
            name="home"
            label="Home"
            rules={[{ 
                required: true, 
                message: "Please select the Home team" 
              }]}
            style={{
                display: 'inline-block',
                width: 'calc(50% - 12px)'
            }}
        >
            <Select
                options={teams}
            />
        </Form.Item>
        <Form.Item
            name="visitor"
            label="Visitor"
            rules={[{ 
                required: true, 
                message: "Please select the Visiting team" 
              }]}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 12px)',
                marginLeft: '24px'
            }}
        >
            <Select
                options={teams}
            />
        </Form.Item>
        <Form.Item 
          name="homeScore"
          label="Home Score"
          style={{
            display: 'inline-block',
            width: 'calc(50% - 12px)'
        }}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="visitorScore"
          label="Visitor Score"
          style={{
            display: 'inline-block',
            width: 'calc(50% - 12px)',
            marginLeft: '24px'
        }}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="snacks"
          label="Snacks"
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="description"
          label="Game Description"
        >
          <TextEditor />
        </Form.Item>
        <Form.Item 
          name="gameNotes"
          label="Game Notes"
        >
          <TextEditor />
        </Form.Item>
        <Form.Item
            name="id"
            type="hidden"
        >
            <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};


