import React, { useState, useRef, useEffect } from "react";
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import { Button, Table, Typography } from 'antd';
import { GameFormModal } from './GameFormModal';
import dayjs from 'dayjs';

const teamName = 'Bulls';

import { nanoid } from "nanoid";
import ListGroup from "react-bootstrap/ListGroup";

import { GameForm } from "./GameForm";
import { TodoItem } from "./TodoItem";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const GamesList = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiLppll.scopes
    });
    const [ modalTitle, setModalTitle ] = useState(null);
    const [ modalItem, setModalItem ] = useState(null);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ games, setGames ] = useState(props.games);
    const [ okText, setOkText ] = useState('Create');

    const columns = [
        {
          title: 'Date',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (text) => dayjs(text).format('ddd, MMM D'),
        },
        {
          title: 'Time',
          dataIndex: 'startTime',
          key: 'startTime',
          render: (text) => dayjs(text).format('h:mm A'),
        },
        {
            title: 'Home',
            dataIndex: 'home',
            key: 'home',
          },
        {
          title: 'Visitor',
          dataIndex: 'visitor',
          key: 'visitor',
        },
          {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
          },
          {
            title: 'Snacks',
            dataIndex: 'snacks',
            key: 'snacks',
          },
          {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            render: (_, item) => {
                return (
                    (typeof item.homeScore === 'undefined') ? '-' : 
                    (typeof item.visitorScore === 'undefined') ? '-' : 
                    (item.homeScore > item.visitorScore) ? `${(item.home == teamName) ? 'W' : 'L'} (${item.homeScore}-${item.visitorScore})` : 
                    (item.homeScore < item.visitorScore) ? `${(item.home == teamName) ? 'L' : 'W'} (${item.homeScore}-${item.visitorScore})` : '-'
                )
            }
          }
        ];

    if (account) {
        columns.push({
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
                return (
                  <Typography.Link onClick={() => editGameModal(record)}>
                    Edit
                  </Typography.Link>
                );
            }
        });
    }


    const addGameModal = () => {
        setModalTitle('Add a Game');
        setModalItem({});
        setIsModalOpen(true);
    }

    const editGameModal = (record) => {
        setModalTitle('Edit a Game');
        setModalItem(record);
        setOkText('Save');
        setIsModalOpen(true);
      };

    const onSave = (values) => {
        return new Promise((resolve, reject) => {
          console.log({values});
          let action = 'POST';
          if (okText == 'Save') action = 'PUT'
          execute(action, protectedResources.apiLppll.gamesEndpoint + (values?.id ? `/${values.id}` : ''), values).then((saveResponse) => {
            execute('GET', protectedResources.apiLppll.gamesEndpoint).then((response) => {
                console.log({response});
                if (response) {
                    setModalItem(null);
                    setIsModalOpen(false);
                    setGames(response);
                    //props.onUpdate();
                    resolve();
                }
            });
    

          }).catch((err)=>reject(err));
        });
      }


    // useEffect(() => {
    //     if (tasks.length - prevTaskLength === -1) {
    //         listHeadingRef.current.focus();
    //     }
    // }, [tasks.length, prevTaskLength]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div className="data-area-div">
            <h2>{props.heading}</h2>
            <AuthenticatedTemplate>
                <Button 
                    type="primary"          
                    onClick={addGameModal}>
                    Add a Game
                </Button>
                <GameFormModal
                    title={modalTitle}
                    open={isModalOpen} 
                    values={modalItem}
                    okText={okText}
                    onCreate={onSave}
                    onCancel={() => {
                        console.log({modalItem})
                        setIsModalOpen(false);
                        setModalItem(null);
                    }}
                />
            </AuthenticatedTemplate>
            { games ? 
                <div style={{ 
                    width: '100%', 
                    overflowX: 'scroll'
                }}>
                    <Table 
                        dataSource={games} 
                        columns={columns} 
                        pagination={false}
                        style={{
                            marginTop: '24px',
                            whiteSpace: 'nowrap'
                        }}    
                    />
                </div>
            : null }
        </div>
    );
}
