import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { EventsList } from '../components/EventsList';
import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { motion } from "framer-motion"

const EventListContent = () => {
    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiLppll.scopes,
    });

    const [eventsData, setEventsData] = useState(null);

    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
    );

    const LoadData = () => {
        execute('GET', protectedResources.apiLppll.eventsEndpoint).then((response) => {
            console.log({response});
            if (response) {
                let sorted = response.sort((a, b) => dayjs(a.eventDate).format('YYYY-MM-DD') === dayjs(b.eventDate).format('YYYY-MM-DD') ? a.eventTime > b.eventTime : a.eventDate > b.eventDate);
                setEventsData(sorted);
            }
        });
    }

    useEffect(() => {
        if (!eventsData) {
            LoadData();
        }
    }, [execute, eventsData])

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return <>
        {eventsData ? 
            <motion.div
                initial={{ opacity: 0, scale: 0.95, marginTop: '48px' }}
                animate={{ opacity: 1, scale: 1, marginTop: '0' }}
                transition={{ ease: "easeOut", duration: 0.33 }}
            >
                <EventsList eventsData={eventsData} heading='Events' /> 
            </motion.div>
    
        : <Spin indicator={antIcon} />}</>;
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const EventsPage = () => {
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            <EventListContent />
        </MsalAuthenticationTemplate>
    );
};
