import { Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import { RouteGuard } from './components/RouteGuard';
import { PageLayout } from './components/PageLayout';
import { NewPageLayout } from './components/Layout';
import { Dashboard } from './pages/Dashboard';
import { EventsPage } from './pages/Events';
import { GamesPage } from './pages/Games';
import { Home } from './pages/Home';
import { appRoles } from './authConfig';

import './styles/App.css';
import 'antd/dist/reset.css';

const Pages = () => {
    return (
        <Routes>
            <Route
                exact
                path="/events"
                element={
                    <EventsPage />
                }
            />
            <Route
                exact
                path="/games"
                element={
                    <GamesPage />
                }
            />
            <Route
                exact
                path="/dashboard"
                element={
                    <RouteGuard roles={[appRoles.Admin]}>
                        <Dashboard />
                    </RouteGuard>
                }
            />
            <Route path="/" element={<Home />} />
        </Routes>
    );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>

            <NewPageLayout>
                <Pages instance={instance} />
            </NewPageLayout>
        </MsalProvider>
    );
};

export default App;
