import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { motion } from 'framer-motion';
import { GamesList } from '../components/GamesList';
import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';

const GamesListContent = () => {
    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiLppll.scopes,
    });

    const [gamesData, setGamesData] = useState(null);

    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
    );

    const LoadData = () => {
        execute('GET', protectedResources.apiLppll.gamesEndpoint).then((response) => {
            console.log({response});
            if (response) {
                setGamesData(response);
            }
        });
    }

    useEffect(() => {
        if (!gamesData) {
            LoadData();
        }
    }, [execute, gamesData])

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return <>
        {gamesData ? 
            <motion.div
                initial={{ opacity: 0, scale: 0.95, marginTop: '48px' }}
                animate={{ opacity: 1, scale: 1, marginTop: '0' }}
                transition={{ ease: "easeOut", duration: 0.33 }}
            >
                <GamesList 
                    games={gamesData} 
                    heading='Games'
                /> 
            </motion.div>
    
        : <Spin indicator={antIcon} />}</>;
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const GamesPage = () => {
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            <GamesListContent />
        </MsalAuthenticationTemplate>
    );
};
