import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { EventItem } from "./EventItem";
import useFetchUnsecured from '../hooks/useFetchUnsecured';

export const NextEvent = (props) => {
    const { instance } = useMsal();

    const { error, executeUnsecured } = useFetchUnsecured();

    const [events, setEvents] = useState(props.events);
    const [isLoading, setIsLoading] = useState(false);



    const eventList = events.slice(0,1).map(event => (
        <EventItem
            id={event.id}
            name={event.name}
            eventDate={event.eventDate}
            eventTime={event.eventTime}
            location={event.location}
            description={event.description}
            key={event.id}
        />
    ));

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div className="data-area-div">
            <div>
                {events ? eventList : 'Loading...'}
            </div>

        </div>
    );
}
