import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";
import ListGroup from "react-bootstrap/ListGroup";

import { EventForm } from "./EventForm";
import { EventItem } from "./EventItem";
import { EventFormModal } from './EventFormModal'
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import dayjs from 'dayjs';

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const EventsList = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiLppll.scopes
    });

    const [events, setEvents] = useState(props.eventsData);
    const [modalItem, setModalItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAddEvent = (event) => {
        const newEvent = {
            owner: account.idTokenClaims?.oid,
            id: nanoid(),
            name: event.name,            
            eventDate: event.eventDate,
            eventTime: event.eventTime,
            location: event.location,
            description: event.description
        };
        console.log({newEvent})
        return execute('POST', protectedResources.apiLppll.eventsEndpoint, newEvent).then((response) => {
            if (response && response.message === 'success') {
                //props.onUpdate();
                let sorted = [...events, newEvent].sort((a, b) => dayjs(a.eventDate).format('YYYY-MM-DD') === dayjs(b.eventDate).format('YYYY-MM-DD') ? a.eventTime > b.eventTime : a.eventDate > b.eventDate);

                setEvents(sorted);
            }
        });
    }

    const handleDeleteEvent = (id) => {
        execute('DELETE', protectedResources.apiLppll.eventsEndpoint + `/${id}`).then((response) => {
            if (response && response.message === 'success') {
                const remainingEvents = events.filter((event) => id !== event.id);
                setEvents(remainingEvents);
            }
        });
    }

    const handleEditEvent = (updatedEvent) => {
        console.log({event: updatedEvent});
        setModalItem(updatedEvent);
        setIsModalOpen(true);
        // execute('PUT', protectedResources.apiLppll.eventsEndpoint + `/${updatedEvent.id}`, updatedEvent).then(() => {
        //     const updatedEvents = events.map((event) => {
        //         if (id === event.id) {
        //             return { ...event, updatedEvent };
        //         }
        //         return event;
        //     });
        //     setEvents(updatedEvents);
        // });
    }
    const onSave = (updatedEvent) => {
        return new Promise((resolve, reject) => {
          console.log('Received values of form: ', updatedEvent);
          execute('PUT', protectedResources.apiLppll.eventsEndpoint + `/${updatedEvent.id}`, updatedEvent).then((putResponse) => {
            execute('GET', protectedResources.apiLppll.eventsEndpoint).then((response) => {
                console.log({response});
                if (response) {
                    setModalItem(null);
                    setIsModalOpen(false);
                    let sorted = response.sort((a, b) => dayjs(a.eventDate).format('YYYY-MM-DD') === dayjs(b.eventDate).format('YYYY-MM-DD') ? a.eventTime > b.eventTime : a.eventDate > b.eventDate);
                    setEvents(sorted);
                    //props.onUpdate();
                    resolve();
                }
            });
    

          }).catch((err)=>reject(err));
        });
      }

    const eventList = events.map(event => (
        <EventItem
            id={event.id}
            name={event.name}
            eventDate={event.eventDate}
            eventTime={event.eventTime}
            location={event.location}
            description={event.description}
            key={event.id}
            editEvent={handleEditEvent}
            deleteEvent={handleDeleteEvent}
        />
    ));

    const listHeadingRef = useRef(null);
    const prevEventLength = usePrevious(events.length);

    useEffect(() => {
        if (events.length - prevEventLength === -1) {
            listHeadingRef.current.focus();
        }
    }, [events.length, prevEventLength]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div className="data-area-div">
            <h1>{props.heading}</h1>
            <EventForm addEvent={handleAddEvent} />
            <h2 id="list-heading" tabIndex="-1" ref={listHeadingRef}></h2>
            <ListGroup className="todo-list">
                {eventList}
            </ListGroup>
            <EventFormModal
                title="Edit an Event" 
                open={isModalOpen} 
                values={modalItem}
                okText='Save'
                onCreate={onSave}
                onCancel={() => {
                    console.log({modalItem})
                    setIsModalOpen(false);
                    setModalItem(null);
                }}
            />
        </div>
    );
}
