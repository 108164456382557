import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Link, useMatches } from 'react-router-dom';
import { Affix, Layout, Menu, theme, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { loginRequest } from "../authConfig";
import teamLogo from '../img/durham-bulls-logo.png';
import { PullToRefresh } from 'antd-mobile';

const { Header, Content, Footer } = Layout;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
export const NewPageLayout = (props) => {
    const { instance } = useMsal();
    const {
        token: { colorBgContainer },
      } = theme.useToken();

    return (
        <Layout className="layout">
          <Header className="header-fixed-top">
            <div className="logo" >
                <Link to="/">
                  <img src={teamLogo} style={{ maxWidth: '32px' }} /> Bulls
                </Link>
            </div>
            <div className="auth-button-container">
                <AuthenticatedTemplate>
                    <Button 
                        type="primary" 
                        ghost
                        onClick={() => instance.logoutRedirect({postLogoutRedirectUri: "/"})}
                    >
                        Sign Out
                    </Button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Button 
                        type="primary" 
                        ghost
                        onClick={() => instance.loginRedirect(loginRequest)}
                    >
                        Sign In
                    </Button>
                </UnauthenticatedTemplate>                    
            </div>
            <AuthenticatedTemplate>
              <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[]}
                items={[{
                  label: (
                      <Link to="/events">
                        Events
                      </Link>
                    ),
                  key: 'events'
                },{
                  label: (
                      <Link to="/games">
                        Games
                      </Link>
                    ),
                  key: 'games'
                }]}
              />
            </AuthenticatedTemplate>
          </Header>
          <Content
            style={{
              padding: '12px',
              marginTop: '64px'
            }}
          >
            <div
              className="site-layout-content"
              style={{
                padding: '12px'
                
              }}
            >
              <PullToRefresh
                pullingText="Pull to Refresh"
                canReleaseText={<Spin indicator={antIcon} />}
                refreshingText={<Spin indicator={antIcon} />}
                completeText={<Spin indicator={antIcon} />}
                onRefresh={() => {
                    window.location.reload(true);
                }}
              >
              {props.children}
            </PullToRefresh>

            </div>
          </Content>
          <Footer
            theme="dark"
            style={{
              textAlign: 'center',
              fontSize: '.8em'
            }}
          >
            <h3>LPPLL Team App</h3>
            Built by Jansson
          </Footer>
        </Layout>
      );
}
