import React, { useState, useRef, useEffect } from "react";
import { useMsal } from '@azure/msal-react';
import useFetchUnsecured from '../hooks/useFetchUnsecured';
import { unprotectedResources } from "../authConfig";
import { NextEvent } from '../components/NextEvent';
import { motion } from "framer-motion"
import { Button, Spin, Skeleton, Popover, Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import teamLogo from '../img/durham-bulls-logo.png';
import { EventsList } from '../components/EventsList';
import { NextGame } from "../components/NextGame";
import { FullSchedule } from '../components/FullSchedule';
import { UpcomingEvents } from "../components/UpcomingEvents";
import dayjs from 'dayjs';

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const Home = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const { error, executeUnsecured } = useFetchUnsecured();
    const [events, setEvents] = useState(null);
    const [games, setGames] = useState(null);
    const [ showAllEvents, setShowAllEvents ] = useState(false);
    const [ showFullSchedule, setShowFullSchedule ] = useState(false);
    const [ nextGame, setNextGame ] = useState(null);

    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
    );

    //console.log({activeAccount});

    const getEvents = () => {
        return new Promise((resolve, reject) => {
            executeUnsecured('GET', unprotectedResources.apiLppll.infoEndpoint + '/events').then((response) => {
                //console.log({response});
                if (response) {
                    
                    setEvents(response.sort((a, b) => 
                        dayjs(a.eventDate).format('YYYY-MM-DD') === dayjs(b.eventDate).format('YYYY-MM-DD') ? 
                            a.eventTime > b.eventTime : a.eventDate > b.eventDate)
                        );
                    resolve(response);
                }
            }).catch((err)=> {
                return reject(err)
            });
        });
      }

      const getSchedule = () => {
        return new Promise((resolve, reject) => {
            executeUnsecured('GET', unprotectedResources.apiLppll.infoEndpoint + '/schedule').then((response) => {
                //console.log({response});
                if (response) {
                    setGames(response);
                    findNextGame(response);
                    resolve(response);
                }
            }).catch((err)=> {
                return reject(err)
            });
        });
      }

      const findNextGame = (games) => {
        let game = games.filter((game) => game.homeScore == game.visitorScore).slice(0,1);
        console.log({game});
        if (game.length > 0);
            setNextGame(game[0]);
        }

      useEffect(() => {
        if (!events) {
            getEvents();
        }
        if (!games) {
            getSchedule();
        }
    }, [executeUnsecured, events, games])

    return (
        <div className="center" style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
            <motion.div
                initial={{ opacity: 0, scale: 0.95, marginTop: '48px' }}
                animate={{ opacity: 1, scale: 1, marginTop: '0' }}
                transition={{ ease: "easeOut", duration: 0.33 }}
                layoutId="logo"
            >
                <img src={teamLogo} style={{ maxWidth: '320px', width: '50%' }} />
            </motion.div>
            { events ? 
                <motion.div
                    initial={{ opacity: 0, scale: 0.95, marginTop: '48px' }}
                    animate={{ opacity: 1, scale: 1, marginTop: '0' }}
                    transition={{ ease: "easeOut", duration: 0.33 }}
                >
                    {/* <br/>
                    <center>
                        <h1 style={{ color: 'red' }}>⛈️ Weather Notice: Thu, Mar 30</h1>
                    </center>
                    <br/>
                    <Card style={{ textAlign: 'left' }}>
                        Today's game has been postponed. Please check back for additional information.<br />
                    </Card>
                    <br /> */}
                    <br />
                    <center>
                        <h1>Next Event</h1>
                    </center>
                    <NextEvent events={events} /> 
                    <br/>
                    { showAllEvents ? 
                        <>
                            <br/>
                            <UpcomingEvents 
                                events={events} 
                                heading='Upcoming Events' 
                                skipFirst={true}
                            /> 
                        </>
                    : 
                        <>
                            <Button 
                                type="primary" 
                                onClick={() => setShowAllEvents(true)}>
                                    Upcoming Events
                            </Button>
                            <br/>
                        </>
                    }
                    <br/>
                </motion.div>
            : 
            <>
                <Spin indicator={antIcon} />
                <Skeleton />
                <Skeleton />
            </> }
            <br/>
            { games ? 
                <motion.div
                    initial={{ opacity: 0, scale: 0.95, marginTop: '48px' }}
                    animate={{ opacity: 1, scale: 1, marginTop: '0' }}
                    transition={{ ease: "easeOut", duration: 0.33 }}
                >
                    <h1>Next Game</h1>
                    { nextGame ? 
                        <>
                            <NextGame game={nextGame} />
                        </>
                    : 'No games scheduled' }
                    <br/>
                    <br/>
                    { showFullSchedule ? 
                        <>
                            <FullSchedule 
                                games={games} 
                                heading='Spring 2023 Schedule'
                            /> 
                        </>
                    : 
                        <>
                            { games?.length > 0 ? <Button type="primary" onClick={() => setShowFullSchedule(true)}>Full Schedule</Button> : '' }                            
                        </>
                    }
                    <br/>
                </motion.div>
            : 
            <>
                <Spin indicator={antIcon} />
                <Skeleton />
                <Skeleton />
            </> }
        </div>
    );
};