import React, { useState, useRef, useEffect } from "react";
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import { Button, Table, Typography } from 'antd';
import { GameFormModal } from './GameFormModal';
import dayjs from 'dayjs';

const teamName = 'Bulls';

import { nanoid } from "nanoid";
import ListGroup from "react-bootstrap/ListGroup";

import { GameForm } from "./GameForm";
import { TodoItem } from "./TodoItem";



export const FullSchedule = (props) => {
    const [ modalTitle, setModalTitle ] = useState(null);
    const [ modalItem, setModalItem ] = useState(null);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ games, setGames ] = useState(props.games);
    const [ okText, setOkText ] = useState('Create');

    const columns = [
        {
          title: 'Date',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (text) => dayjs(text).format('ddd, MMM D'),
        },
        {
          title: 'Time',
          dataIndex: 'startTime',
          key: 'startTime',
          render: (text) => dayjs(text).format('h:mm A'),
        },
        {
            title: 'Home',
            dataIndex: 'home',
            key: 'home',
          },
        {
          title: 'Visitor',
          dataIndex: 'visitor',
          key: 'visitor',
        },
          {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
          },
          {
            title: 'Snacks',
            dataIndex: 'snacks',
            key: 'snacks',
          },
          // {
          //   title: 'Result',
          //   dataIndex: 'result',
          //   key: 'result',
          //   render: (_, item) => {
          //       return (
          //           (typeof item.homeScore === 'undefined') ? '-' : 
          //           (typeof item.visitorScore === 'undefined') ? '-' : 
          //           (item.homeScore > item.visitorScore) ? `${(item.home == teamName) ? 'W' : 'L'} (${item.homeScore}-${item.visitorScore})` : 
          //           (item.homeScore < item.visitorScore) ? `${(item.home == teamName) ? 'L' : 'W'} (${item.homeScore}-${item.visitorScore})` : '-'
          //       )
          //   }
          // }
        ];

    return (
        <div className="data-area-div">
            <h2>{props.heading}</h2>

            { games ? 
                <div style={{ 
                    width: '100%', 
                    overflowX: 'scroll'
                }}>
                    <Table 
                        dataSource={games} 
                        columns={columns} 
                        pagination={false}
                        style={{
                            marginTop: '24px',
                            whiteSpace: 'nowrap'
                        }}    
                    />
                </div>
            : null }
        </div>
    );
}
