import React, { useEffect, useRef, useState } from "react";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { Popconfirm, Button, Card, Space } from "antd";
import { DeleteOutlined, EditOutlined, EnvironmentOutlined, CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { EventFormModal } from "./EventFormModal";
import { AuthenticatedTemplate } from "@azure/msal-react";

const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const EventItem = (props) => {
    const [isEditing, setEditing] = useState(false);
    const [newEvent, setNewEvent] = useState(null);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
      setOpen(true);
    };
    const handleOk = () => {
      setConfirmLoading(true);
      props.deleteEvent(props.id)
    };
    const handleEditEvent = () => {
        setConfirmLoading(false);
        props.editEvent(props);
    }
    const handleCancel = () => {
      console.log('Clicked cancel button');
      setConfirmLoading(false);
      setOpen(false);
    };
    const editFieldRef = useRef(null);
    const editButtonRef = useRef(null);

    const wasEditing = usePrevious(isEditing);

    const handleChange = (e) => {
        setNewEvent(e.target.value);
    }

    const handleDelete = (id) => {
        console.log({id});
        props.deleteEvent(id);
    }

    const handleSubmit = (e) => {
        e.preventDefault();



        if (!newEvent) {
            return;
        }

        props.editEvent(props.id, newEvent);
        setNewEvent(null);
        setEditing(false);
    }
    const dateFormat = props.eventDate ? dayjs(props.eventDate).format('ddd, MMM D').toString() : '?' ;
    const timeFormat = props.eventTime ? dayjs(props.eventTime).format('h:mm A').toString() : '?';
    //console.log({dateFormat});

    const editingTemplate = (
        <div className="todo-edit">
            {props.name}<br/>
            {props.eventDate}<br/>
            {props.eventTime}<br/>
            <p>{props.description}</p>            
        </div>
    );

    const viewTemplate = (
        <Card
            title={props.name}
            bordered={true}
            className='content-card'
            style={{
                marginTop: '24px',
                textAlign: 'left'
            }}
        >
            <Space>
                <CalendarOutlined className="gray" />
                <div>{dateFormat} @ {timeFormat}</div>
            </Space>
            <br/>
            <Space align="start">
                <EnvironmentOutlined className="gray" />
                <div dangerouslySetInnerHTML={{__html: props.location}} />
            </Space>    
            <br/><br/>
            <div dangerouslySetInnerHTML={{__html: props.description}} />
            <AuthenticatedTemplate>
                { props.editEvent ? 
                    <div style={{ float: 'right' }}>
                        <Space>
                            <Button 
                                onClick={handleEditEvent} 
                                title="Edit"
                                icon={<EditOutlined />}
                            >                        
                            </Button>
                            <Popconfirm
                                title="Delete event"
                                description="Are you sure you want to delete this event?"
                                open={open}
                                onConfirm={handleOk}
                                okButtonProps={{
                                    loading: confirmLoading,
                                }}
                                onCancel={handleCancel}
                                >
                                <Button 
                                    onClick={showPopconfirm} 
                                    title="Delete"
                                    icon={<DeleteOutlined />}
                                >                        
                                </Button>
                            </Popconfirm>
                        </Space> 
                    </div>
                 : '' }
            </AuthenticatedTemplate>
        </Card>
    );

    useEffect(() => {

        if (!wasEditing && isEditing) {
            editFieldRef.current.focus();
        }

        if (wasEditing && !isEditing) {
            editButtonRef.current.focus();
        }

    }, [wasEditing, isEditing]);

    return <ListGroupItem className="todo-item">{isEditing ? editingTemplate : viewTemplate}</ListGroupItem>;
}
