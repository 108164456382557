import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Modal, TimePicker, Checkbox, Radio } from 'antd';
import { EventFormModal } from "./EventFormModal";

export const EventForm = (props) => {
    const [event, setEvent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const onCreate = (values) => {
      return new Promise((resolve, reject) => {
        console.log('Received values of form: ', values);
        props.addEvent(values).then((res) => {
          resolve();
          setIsModalOpen(false);
        })  
      });
    }

      //setIsModalOpen(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      form
          .validateFields()
          .then((values) => {
            setConfirmLoading(true);

            setTimeout(() => {
              setIsModalOpen(false);
              setConfirmLoading(false);
            }, 2000);

            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });

      
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!event) {
            return;
        }

        props.addEvent(event);
        setEvent(null);
    }

    const handleChange = (e) => {
        setName(e.target.value);
    }

    const onFinish = (e) => {
      console.log('finish', {e})
    }

    const onFinishFailed = (e) => { 
      console.log('finishFailed', {e})
    }
    return (
      <>
        <Button 
          type="primary"          
          onClick={showModal}>
          Add an Event
        </Button>
        <EventFormModal
          title="Add an Event" 
          open={isModalOpen} 
          okText="Create"
          onCreate={onCreate}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        />
      </>

    );
}
