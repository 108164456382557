import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { EventItem } from "./EventItem";
import { Card, Button, Space } from 'antd';
import useFetchUnsecured from '../hooks/useFetchUnsecured';
import { EnvironmentOutlined, CalendarOutlined, TrophyOutlined, SmileOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

export const NextGame = (props) => {
    const { instance } = useMsal();

    const { error, executeUnsecured } = useFetchUnsecured();

    const [game, setGames] = useState(props.game);
    const [isLoading, setIsLoading] = useState(false);

    let dateFormat = game?.startDate ? dayjs(game?.startDate).format('dddd, MMM D').toString() : '?' ;
    let timeFormat = game?.startTime ? dayjs(game?.startTime).format('h:mm A').toString() : '?';
    let warmupFormat = game?.startDate ? dayjs(game?.startTime).subtract(30, 'minutes').format('h:mm').toString() : '?' ;
    
    const viewTemplate = (
        <Card
            title={`⚾️ ${dateFormat} @ ${timeFormat}`}
            bordered={true}
            style={{
                marginTop: '24px',
                textAlign: 'left'
            }}
        >
            <Space>
                
                <TrophyOutlined className="gray" />
                <div>Teams: {game.visitor} @ {game.home}</div>
            </Space>
            <br/>
            <Space>
                <EnvironmentOutlined className="gray" />
                <div>Location: {game.location}</div>
            </Space>    
            {game.snacks ? 
                <>
                    <br/>
                    <Space>
                        <SmileOutlined className="gray" />
                        <div>Snacks: {game.snacks}</div>
                    </Space>    
                </>
            : 
                ''
            }
            <br/><br/>
            <div>Please arrive ready for warm ups at {warmupFormat}.</div>
        </Card>
    );

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div className="data-area-div">
            <div>
                {game ? viewTemplate : 'Loading...'}
            </div>

        </div>
    );
}
