import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Modal, TimePicker, Checkbox, Radio } from 'antd';
import dayjs from 'dayjs';
import { TextEditor } from './TextEditor';

const { TextArea } = Input;

export const EventFormModal = ({ open, title, values, okText, onCreate, onCancel }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
    if (values && !confirmLoading) {
        console.log({values},{confirmLoading});
        console.log(values?.description);
        form.setFieldsValue({
            id: values?.id,
            name: values?.name,
            eventDate: dayjs(values?.eventDate),
            eventTime: dayjs(values?.eventTime)
        });
        form.setFieldValue('location',values?.location);
        form.setFieldValue('description', values?.description);

    }
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        onCreate(values).then(() => {
            console.log('ok', {values});
          setConfirmLoading(false);
          form.resetFields();
        });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    console.log('reset');
    onCancel();
  };

  
  return (
    <Modal
      open={open}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      style={{
        top: 24,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item 
          name="name"
          label="Event Name"
          rules={[{ 
            required: true, 
            message: "Please enter an event name" 
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="eventDate"
          label="Event Date"
          rules={[{ 
            required: true, 
            message: "Please enter a date for the event" 
          }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item 
          name="eventTime"
          label="Event Time"
          rules={[{ 
            required: true, 
            message: "Please enter a time for the event" 
          }]}
        >
          <TimePicker 
            format='hh:mm A' 
          />
        </Form.Item>
        <Form.Item 
          name="location"
          label="Location"
          rules={[{ 
            required: true, 
            message: "Please enter an event location" 
          }]}
        >
          <TextEditor />
        </Form.Item>
        <Form.Item 
          name="description"
          label="Event Details"
        >
          <TextEditor 
          />
        </Form.Item>
        <Form.Item
            name="id"
            type="hidden"
        >
            <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};


